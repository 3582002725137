import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // -----------------------------------------------------------------------------------------
  // ----------------------------------- หลังบ้าน ---------------------------------------------
  // -----------------------------------------------------------------------------------------
  {
    name: 'loginbackoffice',
    path: '/loginbackoffice',
    component: () => import('@/views/LoginBackoffice'),
    meta: { boAuthentication: false }
  },
  {
    path: '/backoffice',
    component: () => import('@/views/core/Dashboard2'),
    meta: { boAuthentication: true },
    children: [
      {
        name: 'bo-home',
        path: 'bo/home',
        component: () => import('@/views/backoffice/HomeBo'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-customer',
        path: 'customer',
        component: () => import('@/views/backoffice/backoffice/customer/Index'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-customer-update',
        path: 'customer/detail/:id',
        component: () =>
          import('@/views/backoffice/backoffice/customer/Detail'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-product',
        path: 'product',
        component: () => import('@/views/backoffice/backoffice/product/Index'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-product-create',
        path: 'product/detail',
        component: () => import('@/views/backoffice/backoffice/product/Detail')
      },
      {
        name: 'backoffice-product-update',
        path: 'product/detail/:id',
        component: () => import('@/views/backoffice/backoffice/product/Detail'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-receipt',
        path: 'receipt',
        component: () => import('@/views/backoffice/backoffice/receipt/Index'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-receipt-create',
        path: 'receipt/detail',
        component: () => import('@/views/backoffice/backoffice/receipt/Detail'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-receipt-update',
        path: 'receipt/detail/:id',
        component: () => import('@/views/backoffice/backoffice/receipt/Detail'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-receipt-pay',
        path: 'receipt/pay/:id',
        component: () => import('@/views/backoffice/backoffice/receipt/Pay'),
        meta: { boAuthentication: true }
      },

      {
        name: 'backoffice-coupon',
        path: 'coupon',
        component: () => import('@/views/backoffice/backoffice/coupon/Index'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-coupon-create',
        path: 'coupon/detail',
        component: () => import('@/views/backoffice/backoffice/coupon/Detail'),
        meta: { boAuthentication: true }
      },
      {
        name: 'backoffice-coupon-update',
        path: 'coupon/detail/:id',
        component: () => import('@/views/backoffice/backoffice/coupon/Detail'),
        meta: { boAuthentication: true }
      }

      /* แพ็คเกจ */
      // {
      //     name: 'package-your',
      //     path: 'bo/backoffice/package/your',
      //     component: () =>
      //         import ('@/views/backoffice/package/your/Index'),
      //     meta: { boAuthentication: true },
      // },
      // {
      //     name: 'package-payment',
      //     path: 'bo/backoffice/package/payment',
      //     component: () =>
      //         import ('@/views/backoffice/package/payment/Index'),
      //     meta: { boAuthentication: true },
      // },
    ]
  },
  // -----------------------------------------------------------------------------------------
  // ----------------------------------- Client Route ----------------------------------------
  // -----------------------------------------------------------------------------------------
  {
    name: '',
    path: '/',
    component: () => import('@/views/Login'),
    meta: { authentication: false }
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/Login'),
    meta: { authentication: false }
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/Register'),
    meta: { authentication: false }
  },
  {
    path: '/pages',
    component: () => import('@/views/core/Dashboard'),
    meta: { authentication: true },
    children: [
      {
        name: 'home',
        path: 'home',
        component: () => import('@/views/pages/Home'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'car',
        path: 'car/car',
        component: () => import('@/views/pages/car/car/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'car-create',
        path: 'car/car/detail',
        component: () => import('@/views/pages/car/car/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'car-update',
        path: 'car/car/detail/:id',
        component: () => import('@/views/pages/car/car/Detail'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        name: 'car-bancar',
        path: 'car/bancar',
        component: () => import('@/views/pages/car/bancar/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'car-bancar-create',
        path: 'car/bancar/detail',
        component: () => import('@/views/pages/car/bancar/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'car-bancar-update',
        path: 'car/bancar/detail/:id',
        component: () => import('@/views/pages/car/bancar/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'customer',
        path: 'customer/customer',
        component: () => import('@/views/pages/customer/customer/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'customer-customer-create',
        path: 'customer/customer/detail',
        component: () => import('@/views/pages/customer/customer/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'customer-customer-update',
        path: 'customer/customer/detail/:id',
        component: () => import('@/views/pages/customer/customer/Detail'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        name: 'customer-group',
        path: 'customer/group',
        component: () => import('@/views/pages/customer/group/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'customer-group-create',
        path: 'customer/group/detail',
        component: () => import('@/views/pages/customer/group/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'customer-group-update',
        path: 'customer/group/detail/:id',
        component: () => import('@/views/pages/customer/group/Detail'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        name: 'customer-agent',
        path: 'customer/agent',
        component: () => import('@/views/pages/customer/agent/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'customer-agent-create',
        path: 'customer/agent/detail',
        component: () => import('@/views/pages/customer/agent/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'customer-agent-update',
        path: 'customer/agent/detail/:id',
        component: () => import('@/views/pages/customer/agent/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      /* ติดตามงาน */
      {
        name: 'follow-tax',
        path: 'follow/tax/:tab?',
        component: () => import('@/views/pages/follow/tax/Index'),
        meta: { authentication: true, permission: 'job_follow_up' }
      },
      {
        name: 'follow-tax-paytax',
        path: 'follow/tax/paytax',
        props: true,
        component: () => import('@/views/pages/follow/tax/PayTax'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'follow-insurance',
        path: 'follow/insurance',
        component: () => import('@/views/pages/follow/insurance/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'follow-transport',
        path: 'follow/transport/:tab?',
        component: () => import('@/views/pages/follow/transport/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'follow-transport-paytax',
        path: 'follow/transport/paytax/:id',
        props: true,
        component: () => import('@/views/pages/follow/transport/PayTax'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'follow-alert',
        path: 'follow/alert',
        component: () => import('@/views/pages/follow/alert/Index'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        name: 'follow-alert-create',
        path: 'follow/alert/detail',
        component: () => import('@/views/pages/follow/alert/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'follow-alert-customer',
        path: 'follow/alert-customer',
        component: () => import('@/views/pages/follow/alert-customer/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'follow-alert-update',
        path: 'follow/alert/detail/:id',
        component: () => import('@/views/pages/follow/alert/Detail'),
        meta: { authentication: true, permission: 'public' }
      },

      /* แพ็คเกจ */
      {
        name: 'package-your',
        path: 'package/your',
        component: () => import('@/views/pages/package/your/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'package-your-pay',
        path: 'package/your/pay/:id',
        component: () => import('@/views/pages/package/your/Pay'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'package-payment',
        path: 'package/payment',
        component: () => import('@/views/pages/package/payment/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'package-payment-detail',
        path: 'package/payment/detail/:id',
        component: () => import('@/views/pages/package/payment/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'package-payment-pay',
        path: 'package/payment/pay/:id',
        component: () => import('@/views/pages/package/payment/Pay'),
        meta: { authentication: true, permission: 'public' }
      },

      /* ตรวจสอบสถานะเอกสาร */
      // {
      //   name: 'document_status',
      //   path: 'document_status/:id',
      //   component: () => import('@/views/pages/car/car/Index'),
      // },
      /* ใบเสนอราคา */
      {
        name: 'document-quotation',
        path: 'document/quotation',
        component: () => import('@/views/pages/document/quotation/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-quotation-test',
        path: 'document/quotation/test',
        component: () => import('@/views/pages/document/quotation/test'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-quotation-create',
        path: 'document/quotation/detail',
        component: () => import('@/views/pages/document/quotation/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-quotation-update',
        path: 'document/quotation/detail/:id',
        component: () => import('@/views/pages/document/quotation/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-quotation-pay',
        path: 'document/quotation/pay/:id',
        component: () => import('@/views/pages/document/quotation/Pay'),
        meta: { authentication: true, permission: 'public' }
      },

      /* ใบแจ้งหนี้ */
      {
        name: 'document-invoice',
        path: 'document/invoice',
        component: () => import('@/views/pages/document/invoice/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-invoice-create',
        path: 'document/invoice/detail',
        component: () => import('@/views/pages/document/invoice/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-invoice-update',
        path: 'document/invoice/detail/:id',
        component: () => import('@/views/pages/document/invoice/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-invoice-pay',
        path: 'document/invoice/pay/:id',
        component: () => import('@/views/pages/document/invoice/Pay'),
        meta: { authentication: true, permission: 'public' }
      },

      /* ใบเสร็จรับเงิน */
      {
        name: 'document-receipt',
        path: 'document/receipt',
        component: () => import('@/views/pages/document/receipt/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-receipt-create',
        path: 'document/receipt/detail',
        component: () => import('@/views/pages/document/receipt/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-receipt-update',
        path: 'document/receipt/detail/:id',
        component: () => import('@/views/pages/document/receipt/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-receipt-pay',
        path: 'document/receipt/pay/:id',
        component: () => import('@/views/pages/document/receipt/Pay'),
        meta: { authentication: true, permission: 'public' }
      },

      /* ใบลดหนี้ */
      {
        name: 'document-debit',
        path: 'document/debit',
        component: () => import('@/views/pages/document/debit/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-debit-create',
        path: 'document/debit/detail',
        component: () => import('@/views/pages/document/debit/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-debit-update',
        path: 'document/debit/detail/:id',
        component: () => import('@/views/pages/document/debit/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-debit-pay',
        path: 'document/debit/pay/:id',
        component: () => import('@/views/pages/document/debit/Pay'),
        meta: { authentication: true, permission: 'public' }
      },

      /* ใบเพิ่มหนี้ */
      {
        name: 'document-credit',
        path: 'document/credit',
        component: () => import('@/views/pages/document/credit/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-credit-create',
        path: 'document/credit/detail',
        component: () => import('@/views/pages/document/credit/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-credit-update',
        path: 'document/credit/detail/:id',
        component: () => import('@/views/pages/document/credit/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'document-credit-pay',
        path: 'document/credit/pay/:id',
        component: () => import('@/views/pages/document/credit/Pay'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        path: 'product/',
        component: () => import('@/views/pages/product/product/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'product',
        path: 'product/product',
        component: () => import('@/views/pages/product/product/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'product-create',
        path: 'product/product/detail',
        component: () => import('@/views/pages/product/product/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'product-update',
        path: 'product/product/detail/:id',
        component: () => import('@/views/pages/product/product/Detail'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        name: 'product-insurance',
        path: 'product/insurance',
        component: () => import('@/views/pages/product/insurance/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'product-insurance-create',
        path: 'product/insurance/detail',
        component: () => import('@/views/pages/product/insurance/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'product-insurance-update',
        path: 'product/insurance/detail/:id',
        component: () => import('@/views/pages/product/insurance/Detail'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        name: 'product-transport',
        path: 'product/transport',
        component: () => import('@/views/pages/product/transport/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'product-transport-create',
        path: 'product/transport/detail',
        component: () => import('@/views/pages/product/transport/Detail'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'product-transport-update',
        path: 'product/transport/detail/:id',
        component: () => import('@/views/pages/product/transport/Detail'),
        meta: { authentication: true, permission: 'public' }
      },

      /* ค่าใช้จ่าย */
      {
        name: 'expenses-expenditure',
        path: 'expenses/expenditure',
        component: () => import('@/views/pages/expenses/expenditure/Index'),
        meta: { authentication: true, permission: 'expenses' }
      },
      {
        name: 'expenses-expenditure-create',
        path: 'expenses/expenditure/detail',
        component: () => import('@/views/pages/expenses/expenditure/Detail'),
        meta: { authentication: true, permission: 'expenses' }
      },
      {
        name: 'expenses-expenditure-update',
        path: 'expenses/expenditure/detail/:id',
        component: () => import('@/views/pages/expenses/expenditure/Detail'),
        meta: { authentication: true, permission: 'expenses' }
      },
      // {
      //   name: 'expenses-withholding',
      //   path: 'expenses/withholding',
      //   component: () => import('@/views/pages/expenses/withholding/Index'),
      // },
      {
        name: 'expenses-cartax',
        path: 'expenses/cartax',
        component: () => import('@/views/pages/expenses/cartax/Index'),
        meta: { authentication: true, permission: 'expense_car_tax' }
      },
      {
        name: 'expenses-cartax-update',
        path: 'expenses/cartax/:id',
        component: () => import('@/views/pages/expenses/cartax/Detail'),
        meta: { authentication: true, permission: 'expense_car_tax' }
      },

      {
        name: 'expenses-insurance',
        path: 'expenses/insurance',
        component: () => import('@/views/pages/expenses/insurance/Index'),
        meta: { authentication: true, permission: 'expense_insurance' }
      },
      {
        name: 'expenses-insurance-current',
        path: 'expenses/insurance-current/:id',
        component: () =>
          import('@/views/pages/expenses/insurance/IndexCurrent'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'expenses-insurance-current-create',
        path: 'expenses/insurance-current/detail',
        props: true,
        component: () =>
          import('@/views/pages/expenses/insurance/DetailCurrent'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'expenses-insurance-current-update',
        path: 'expenses/insurance-current/detail/:id',
        component: () =>
          import('@/views/pages/expenses/insurance/DetailCurrent'),
        meta: { authentication: true, permission: 'public' }
      },

      //รายชื่อผู้จำหน่าย
      {
        name: 'expenses-dealer',
        path: 'expenses/dealer',
        component: () => import('@/views/pages/expenses/dealer/Index'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        name: 'expenses-dealer-create',
        path: 'expenses/dealer/detail',
        component: () => import('@/views/pages/expenses/dealer/Detail'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        name: 'expenses-dealer-update',
        path: 'expenses/dealer/detail/:id',
        component: () => import('@/views/pages/expenses/dealer/Detail'),
        meta: { authentication: true, permission: 'public' }
      },

      {
        name: 'expenses-expensegroup',
        path: 'expenses/expensegroup',
        component: () => import('@/views/pages/expenses/expensegroup/Index'),
        meta: { authentication: true, permission: 'expense_category' }
      },

      {
        name: 'expenses-expensegroup-create',
        path: 'expenses/expensegroup/detail',
        component: () => import('@/views/pages/expenses/expensegroup/Detail'),
        meta: { authentication: true, permission: 'expense_category' }
      },

      {
        name: 'expenses-expensegroup-update',
        path: 'expenses/expensegroup/detail/:id',
        component: () => import('@/views/pages/expenses/expensegroup/Detail'),
        meta: { authentication: true, permission: 'expense_category' }
      },

      {
        name: 'setting-business-branch',
        path: 'setting/business-branch',
        component: () => import('@/views/pages/setting/business-branch/Index'),
        meta: { authentication: true, permission: 'setting' }
      },
      {
        name: 'setting-business-branch-create',
        path: 'setting/business-branch/detail',
        component: () => import('@/views/pages/setting/business-branch/Detail'),
        meta: { authentication: true, permission: 'setting' }
      },
      {
        name: 'setting-business-branch-update',
        path: 'setting/business-branch/detail/:id',
        component: () => import('@/views/pages/setting/business-branch/Detail'),
        meta: { authentication: true, permission: 'setting' }
      },

      {
        name: 'setting-business',
        path: 'setting/business',
        component: () => import('@/views/pages/setting/business/Index'),
        meta: { authentication: true, permission: 'setting' }
      },

      {
        name: 'setting-document',
        path: 'setting/document',
        component: () => import('@/views/pages/setting/document/Index'),
        meta: { authentication: true, permission: 'setting' }
      },
      {
        name: 'setting-user-group',
        path: 'setting/user-group',
        component: () => import('@/views/pages/setting/user-group/Index'),
        meta: { authentication: true, permission: 'setting' }
      },
      {
        name: 'setting-user',
        path: 'setting/user',
        component: () => import('@/views/pages/setting/user/Index'),
        meta: { authentication: true, permission: 'setting' }
      },
      {
        name: 'setting-insurance',
        path: 'setting/insurance',
        component: () => import('@/views/pages/setting/insurance/Index'),
        meta: { authentication: true, permission: 'setting' }
      }, {
        name: 'setting-sms',
        path: 'setting/sms',
        component: () => import('@/views/pages/setting/sms/Index'),
        meta: { authentication: true, permission: 'setting' }
      }, {
        name: 'setting-mail',
        path: 'setting/mail',
        component: () => import('@/views/pages/setting/mail/Index'),
        meta: { authentication: true, permission: 'setting' }
      },
      /* {
              name: 'setting-user-create',
              path: 'setting/user/detail',
              component: () => import('@/views/pages/setting/user/Detail'),
            },
            {
              name: 'setting-user-update',
              path: 'setting/user/detail/:id',
              component: () => import('@/views/pages/setting/user/Detail'),
            }, */

      /* setup */
      // {
      //   name: 'setup-business-branch-create',
      //   path: 'setup/business-branch/detail',
      //   props: true,
      //   component: () => import('@/views/pages/setup/business-branch/Detail'),
      //   meta: { authentication: true, permission: 'public' }
      // },  {
      {
        name: 'setup-business-branch-update',
        path: 'setup/business-branch/:id',
        component: () => import('@/views/pages/setup/business-branch/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'setup-business-branch-create',
        path: 'setup/business-branch',
        component: () => import('@/views/pages/setup/business-branch/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'setup-insurance',
        path: 'setup/insurance/:id',
        component: () => import('@/views/pages/setup/insurance/Index'),
        meta: { authentication: true, permission: 'public' }
      }, {
        name: 'setup-business',
        path: 'setup/business/:id',
        component: () => import('@/views/pages/setup/business/Index'),
        meta: { authentication: true, permission: 'public' }
      }, {
        name: 'setup-document',
        path: 'setup/document/:id',
        component: () => import('@/views/pages/setup/document/Index'),
        meta: { authentication: true, permission: 'public' }
      }, {
        name: 'setup-price-product',
        path: 'setup/price-product/:id',
        component: () => import('@/views/pages/setup/price-product/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      /* รายงาน */
      {
        name: 'report-quotations',
        path: 'report/quotations',
        component: () => import('@/views/pages/report/quotations/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'report-invoice',
        path: 'report/invoice',
        component: () => import('@/views/pages/report/invoice/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'report-totalsales',
        path: 'report/totalsales',
        component: () => import('@/views/pages/report/totalsales/Index'),
        meta: { authentication: true, permission: 'report_total_sales' }
      },
      {
        name: 'report-totalsales-update',
        path: 'report/totalsales/detail',
        component: () => import('@/views/pages/report/totalsales/Detail'),
        meta: { authentication: true, permission: 'report_total_sales' }
      },
      {
        name: 'report-productsales',
        path: 'report/productsales',
        component: () => import('@/views/pages/report/productsales/Index'),
        meta: { authentication: true, permission: 'report_product_sales' }
      },
      {
        name: 'report-employeesales',
        path: 'report/employeesales',
        component: () => import('@/views/pages/report/employeesales/Index'),
        meta: { authentication: true, permission: 'report_staff_sales' }
      },
      {
        name: 'report-profit',
        path: 'report/profit',
        component: () => import('@/views/pages/report/profit/Index'),
        meta: { authentication: true, permission: 'report_profit_loss' }
      },
      {
        name: 'report-cost',
        path: 'report/cost',
        component: () => import('@/views/pages/report/cost/Index'),
        meta: { authentication: true, permission: 'report_outgoing_cost' }
      },
      {
        name: 'report-commission',
        path: 'report/commission',
        component: () => import('@/views/pages/report/commission/Index'),
        meta: { authentication: true, permission: 'report_commission' }
      },
      {
        name: 'report-salary',
        path: 'report/salary',
        component: () => import('@/views/pages/report/salary/Index'),
        meta: { authentication: true, permission: 'salary' }
      },
      {
        name: 'report-insurance',
        path: 'report/insurance',
        component: () => import('@/views/pages/report/insurance/Index'),
        meta: { authentication: true, permission: 'public' }
      },
      {
        name: 'report-edit-document',
        path: 'report/edit-document',
        component: () => import('@/views/pages/report/edit-document/Index'),
        meta: { authentication: true, permission: 'public' }
      }

    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
