<template>
  <v-menu offset-y v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-if="statusId == 0"
        :small="small"
        class="white--text"
        color="grey"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>รอดำเนินการ</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="statusId == 1 && (docTypeId == 2 || docTypeId == 4)"
        :small="small"
        class="white--text"
        color="success"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>เก็บเงินแล้ว</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="statusId == 1 && docTypeId == 3"
        :small="small"
        class="white--text"
        color="success"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>คืนเงินแล้ว</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="statusId == 1 && docTypeId == 5"
        :small="small"
        class="white--text"
        color="green"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>จ่ายเงินแล้ว</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="statusId == 1"
        :small="small"
        class="white--text"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>เปิดบิลแล้ว</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="statusId == 2"
        :small="small"
        class="white--text"
        color="error"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>ยกเลิก</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
    </template>

    <v-list v-if="statusId == 0 || (statusId == 1 && docTypeId == 2)">
      <template v-for="item in list">
        <v-list-item
          :key="item.value"
          link
          dense
          v-if="item.value != status"
          @click="onSelected(item.value)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    
    <v-dialog persistent scrollable max-width="500" v-model="dialogCancel">
      <DocumentCancelWithNote
        :item="dialogCancelData"
        @onClose="dialogCancel = false"
        @onSubmit="onSubmitCancel"
      />
    </v-dialog>

    <v-dialog persistent scrollable max-width="500" v-model="dialogDelete">
      <DocumentDeleteWithNote
        :item="dialogDeleteData"
        @onClose="dialogDelete = false"
        @onSubmit="onSubmitDelete"
      />
    </v-dialog>

  </v-menu>
</template>

<script>
import DocumentCancelWithNote from '@/components/DocumentCancelWithNote.vue';
import DocumentDeleteWithNote from '@/components/DocumentDeleteWithNote.vue';

export default {
  components: {
    DocumentCancelWithNote,
    DocumentDeleteWithNote,
  },
  model: {
    prop: 'status',
    event: 'change',
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    status: {
      type: [String, Number],
      default: 'active',
    },
    small: {
      type: Boolean,
      default: false,
    },
    statusId: {
      required: false,
      type: [String, Number],
      default: 0,
    },
    docTypeId: {
      required: false,
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      menu: false,
      dialogCancel: false,
      dialogCancelData: {
        document_id: '',
        note: '',
      },
      dialogDelete: false,
      dialogDeleteData: {
        document_id: '',
        note: '',
      },
      note: '',
      docType: [
        'quotation',
        'invoice',
        'receipt',
        'debit',
        'credit',
        'expense',
      ],
      list: [
        { text: 'เก็บเงิน', value: 10, color: '' },
        { text: 'ค้างชำระ', value: 11, color: '' },
        { text: 'ยกเลิก', value: 12, color: '' },
        { text: 'ลบ', value: 15, color: '' },
      ],
    };
  },
  async created() {
    if (this.docTypeId == 0) {
      this.list = [
        { text: 'เก็บเงิน', value: 10, color: '' },
        { text: 'ค้างชำระ', value: 11, color: '' },
        { text: 'ยกเลิก', value: 12, color: '' },
        { text: 'ลบ', value: 15, color: '' },
      ];
    } else if (this.docTypeId == 1) {
      this.list = [
        { text: 'เก็บเงิน', value: 10, color: '' },
        { text: 'ยกเลิก', value: 12, color: '' },
        { text: 'ลบ', value: 15, color: '' },
      ];
    } else if (this.docTypeId == 2) {
      if (this.statusId == 0) {
        this.list = [
          { text: 'เก็บเงิน', value: 10, color: '' },
          { text: 'ยกเลิก', value: 12, color: '' },
          { text: 'ลบ', value: 15, color: '' },
        ];
      } else if (this.statusId == 1) {
        this.list = [
          { text: 'เก็บเงินเพิ่ม', value: 13, color: '' },
          { text: 'คืนเงิน', value: 14, color: '' },
          // { text: 'ลบ', value: 15, color: '' },
        ];
      }
    } else if (this.docTypeId == 3) {
      this.list = [
        { text: 'คืนเงิน', value: 10, color: '' },
        { text: 'ยกเลิก', value: 12, color: '' },
        { text: 'ลบ', value: 15, color: '' },
      ];
    } else if (this.docTypeId == 4) {
      this.list = [
        { text: 'เก็บเงิน', value: 10, color: '' },
        { text: 'ยกเลิก', value: 12, color: '' },
        { text: 'ลบ', value: 15, color: '' },
      ];
    } else if (this.docTypeId == 5) {
      this.list = [
        { text: 'จ่ายเงิน', value: 10, color: '' },
        { text: 'ยกเลิก', value: 12, color: '' },
      ];
    }

    if (this.statusId == 3) this.list = [];
  },
  methods: {
    onSelected(status) {
      if (status == 10) {
        if (this.docTypeId == 5) {
          this.$router.push({
            name: 'expenses-expenditure-update',
            params: { id: this.id },
            query: { payment: true },
          });
        } else {
          this.$router.push({
            name: `document-${this.docType[this.docTypeId]}-pay`,
            params: { id: this.id },
            query: { pay: true },
          });
        }
      } else if (status == 11) {
        this.openQuotationBill();
      } else if (status == 12) {
        if (this.docTypeId == 5) {
          this.cancel_expense();
        } else {
          this.dialogCancelData = {
            document_id: this.id,
            note: '',
          },
          this.menu = false
          this.dialogCancel = true
          // this.cancel()
        }
      } else if (status == 13) {
        this.$router.push({
          name: `document-credit-update`,
          params: { id: this.id },
        });
      } else if (status == 14) {
        this.$router.push({
          name: `document-debit-update`,
          params: { id: this.id },
        });
      } else if (status == 15) {
        this.dialogDeleteData = {
          document_id: this.id,
          note: '',
        },
        this.menu = false
        this.dialogDelete = true
        // this.delete();
      }
    },
    async openQuotationBill() {
      this.$alertConfirm({ title: `ยืนยันการค้างชำระ`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { document_id: this.id },
                this.$privateKey,
                {
                  noTimestamp: true,
                }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/document/open_quotation_bill`, body)
              .then(async (res) => {
                this.$router.push({
                  name: `document-invoice-update`,
                  params: { id: res.result },
                });
                this.$emit('success');
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    async cancel() {
      let body = {
        token: this.$jwt.sign(
          {
            document_id: this.id,
            note: this.note,
          },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/cancel_document`, body)
        .then((res) => {
          if (res.status == 'overdue')
            this.$alertServerError({
              title:
                'เกินระยะเวลายกเลิก หากต้องการยกเลิก โปรดใช้บัญชี เจ้าของ',
            });
          this.$emit('success');
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async delete() {
      let body = {
        token: this.$jwt.sign(
          {
            document_id: this.id,                
            note: this.note,
          },
          this.$privateKey,
          { noTimestamp: true, }
        )
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/delete_document`, body)
        .then((res) => {
          if (res.status == 'overdue') {
            this.$alertServerError({
              title:
                'เกินระยะเวลายกเลิก หากต้องการยกเลิก โปรดใช้บัญชี เจ้าของ',
            });
          } else if (res.status == `no premission to delete document`) {
            this.$alertServerError({
              title: `กรุณาติดต่อ แอดมินเพื่อลบ`,
            });
          } else this.$alertSuccess({ title: res.status });
          this.$emit('success');
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: `กรุณาติดต่อ แอดมินเพื่อลบ` });
        });
    },
    cancel_expense() {
      this.$alertConfirm({ title: `ยืนยันยกเลิกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ expense_id: this.id }, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/expense/cancel_expense`, body)
              .then((res) => {
                this.$emit('success');
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    onSubmitCancel(val) {
      this.note = val.note;
      this.dialogCancel = false;
      this.menu = false
      this.cancel();
    },
    onSubmitDelete(val) {
      this.note = val.note;
      this.dialogDelete = false;
      this.menu = false
      this.delete();
    },
  },
  computed: {
    color() {
      let color = 'success';
      this.list.forEach((item) => {
        if (this.status == item.value) color = item.color;
      });
      return color;
    },
  },
};
</script>
