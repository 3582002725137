<template>
  <div class="d-flex align-center pa-5">
    <!-- <div class="grey--text mr-5">Item per page</div>
    <div style="width: 80px">
      <v-select
        color="primary"
        dense
        hide-details
        :items="form.list"
        v-model="form.itemsPerPage"
        @change="changeItemsPerPage"
      />
    </div> -->
    <v-spacer />
    <v-pagination
      v-model="form.page"
      @input="$emit('change-page', form.page)"
      :length="length"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  props: {
    length: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        page: 1,
      },
    };
  },
};
</script>
