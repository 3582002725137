import Vue from 'vue';

Vue.prototype.$error_text = 'ข้อมูลไม่ถูกต้อง.';
Vue.prototype.$error_empty = 'กรุณากรอกข้อมูล.';
Vue.prototype.$table_loading_text = 'กำลังโหลด... รอสักครู่';
Vue.prototype.$formatDate = 'DD/MM/YYYY';
Vue.prototype.$formatTime = 'HH:mm';
Vue.prototype.$formatDateTime = 'DD/MM/YYYY HH:mm';

Vue.prototype.$isDebug = true;

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueSignature from 'vue-signature-pad';
Vue.use(VueSignature);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
export const alertSuccess = newOption => {
  let option = {
    customClass: {
      title: 'app-btn-alert-title-success'
    },

    icon: 'success',
    title: 'Success',
    showConfirmButton: false,
    timer: 2000
  };
  Vue.swal({ ...option, ...newOption });
};

/* alert function for call api fail */
export const alertServerError = newOption => {
  let option = {
    customClass: {
      title: 'app-btn-alert-title'
    },

    icon: 'error',
    title: 'Server Error',
    showConfirmButton: true
  };
  Vue.swal({ ...option, ...newOption });
};

// const swalWithBootstrapButtons = Vue.swal.mixin({
//   customClass: {
//     confirmButton: 'btn btn-success',
//     cancelButton: 'btn btn-danger'
//   },
//   buttonsStyling: false
// })

export const alertConfirm = newOption => {
  let option = {
    customClass: {
      title: 'app-btn-alert-title',
      confirmButton: 'app-btn-alert-confirm v-btn',
      cancelButton: 'app-btn-alert-cancel v-btn'
    },
    // buttonsStyling: false,

    icon: 'warning',
    title: 'Confirm ?',

    showConfirmButton: true,
    confirmButtonText: 'ยืนยัน',
    confirmButtonColor: '#2ba9fc',

    showCancelButton: true,
    cancelButtonText: 'ยกเลิก',
    cancelButtonColor: '#999999'
  };
  return Vue.swal({ ...option, ...newOption });
};
export const alertWarning = newOption => {
  let option = {
    customClass: {
      title: 'app-btn-alert-title-success',
      icon: 'icon '
    },

    icon: 'success',
    title: 'Success',
    showConfirmButton: false,
    timer: 1500
  };
  Vue.swal({ ...option, ...newOption });
};

Vue.prototype.$alertSuccess = alertSuccess;
Vue.prototype.$alertConfirm = alertConfirm;
Vue.prototype.$alertServerError = alertServerError;

Vue.filter('price', function(num) {
  if (!num) return 0;
  num = parseInt(num);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

import MenuShipDocument from '@/components/MenuShipDocument';
Vue.component('menu-ship-document', MenuShipDocument);

import MenuShipReceipt from '@/components/MenuShipReceipt';
Vue.component('menu-ship-receipt', MenuShipReceipt);

import MenuShip from '@/components/MenuShip';
Vue.component('menu-ship', MenuShip);

import TablePagination from '@/components/core/TablePagination.vue';
Vue.component('table-pagination', TablePagination);

import TablePaginationCustom from '@/components/core/TablePaginationCustom.vue';
Vue.component('table-pagination-custom', TablePaginationCustom);

import TablePaginationNoItemPerPage from '@/components/core/TablePaginationNoItemPerPage.vue';
Vue.component(
  'table-pagination-no-item-per-page',
  TablePaginationNoItemPerPage
);

Vue.prototype.$defaultImage = 'http://via.placeholder.com/200x200?text=image';

export const alertError = newOption => {
  let option = {
    customClass: {
      title: 'app-btn-alert-title-error'
    },
    icon: 'error',
    title: 'Error',
    showConfirmButton: true
  };
  Vue.swal({ ...option, ...newOption });
};

Vue.prototype.$alertError = alertError;