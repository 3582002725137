import axios from 'axios';
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
const jwt = require('jsonwebtoken');

let accessToken = localStorage.getItem('access_token') || null;
let boAccessToken = localStorage.getItem('bo_access_token') || null;
let authHeader = null;

function isTokenExpired(token) {
  if (!token) {
    // If no token is provided, treat it as expired
    const error = new Error('No token provided');
    console.log(error.message);
    return true;
  }
  try {
    const decodeFunction = Vue.prototype.$jwt.decode;
    const privateKey = Vue.prototype.$privateKey;
    const tokenWithoutBearer = token.replace('Bearer ', '');
    const decoded = decodeFunction(tokenWithoutBearer, privateKey);
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp < currentTime;
  } catch (error) {
    console.log('Token decoding failed:', error);
    return true;
  }
}

// API request interceptor
axios.interceptors.request.use(function(config) {
  accessToken = localStorage.getItem('access_token');
  boAccessToken = localStorage.getItem('bo_access_token');

  if (accessToken) {
    if (isTokenExpired(accessToken)) {
      console.log('isTokenExpired');
      window.location.href = '/';
    }
    config.headers.Authorization = accessToken;
    authHeader = accessToken;
  }
  if (boAccessToken) {
    config.headers.Authorization = boAccessToken;
    authHeader = boAccessToken;
  }

  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

  return config;
}, function(error) {
  console.error('Request error:', error);
  Sentry.captureException(error);
  return Promise.reject(error);
});

// API response interceptor
axios.interceptors.response.use(
  function(response) {
    return Promise.resolve(response.data);
  },
  function(error) {
    if (error.response) {
      // Server responded with a status code outside the range of 2xx
      console.error('Response error:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });

      if (error.response.status === 401) {
        console.error('401 error action:', error.response.data.action);
        console.error('Authorization header:', authHeader);
        if (error.response.data.action != 'authen') {
          window.location.href = '/';
        }  
        return Promise.reject(error.response.data);
      } else if (error.response.status === 400) {
        console.error('400 error data:', error.response.data);
        console.error('Authorization header:', authHeader);
        return Promise.reject(error.response.data);
      } else {
        console.error('Other error data:', error.response.data);
        console.error('Authorization header:', authHeader);
        return Promise.reject(error.response.data);
      }
    } else if (error.request) {
      // Request was made but no response was received
      console.error('Network or other error:', {
        message: error.message,
        config: error.config
      });
      Sentry.captureException(error, {
        extra: {
          message: error.message,
          config: error.config
        }
      });
      return Promise.reject(error);
    } else {
      // Something happened in setting up the request
      console.error('Error setting up request:', error.message);
      Sentry.captureException(error, {
        extra: {
          message: error.message
        }
      });
      return Promise.reject(error);
    }
  }
);

Vue.prototype.$axios = axios;
