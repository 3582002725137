<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-if="statusId == 0"
        :small="small"
        class="white--text"
        color="grey"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>รอดำเนินการ</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="(statusId == 1 && docTypeId == 2) || docTypeId == 4"
        :small="small"
        class="white--text"
        color="success"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>เก็บเงินแล้ว</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="statusId == 1 && docTypeId == 3"
        :small="small"
        class="white--text"
        color="success"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>คืนเงินแล้ว</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="statusId == 1"
        :small="small"
        class="white--text"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>เปิดบิลแล้ว</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
      <v-chip
        v-else-if="statusId == 2"
        :small="small"
        class="white--text"
        color="error"
        v-bind="attrs"
        v-on="on"
      >
        <template><div>ยกเลิก</div></template>
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
    </template>

    <v-list v-if="statusId == 0 || (statusId == 1 && docTypeId == 2)">
      <template v-for="item in list">
        <v-list-item
          :key="item.value"
          link
          dense
          v-if="item.value != status"
          @click="onSelected(item.value)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  model: {
    prop: 'status',
    event: 'change'
  },
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    status: {
      type: [String, Number],
      default: 'active'
    },
    small: {
      type: Boolean,
      default: false
    },
    statusId: {
      required: false,
      type: [String, Number],
      default: 0
    },
    docTypeId: {
      required: false,
      type: [String, Number],
      default: 0
    }
  },

  data() {
    return {
      docType: ['quotation', 'invoice', 'receipt', 'debit', 'credit'],
      list: [
        { text: 'เก็บเงิน', value: 10, color: '' },
        { text: 'ค้างชำระ', value: 11, color: '' },
        { text: 'ยกเลิก', value: 12, color: '' }
      ]
    };
  },
  async created() {
    if (this.statusId == 0) {
      this.list = [
        { text: 'เก็บเงิน', value: 10, color: '' },
        { text: 'ยกเลิก', value: 12, color: '' }
      ];
    } else if (this.statusId == 1) {
      this.list = [];
    }
  },
  methods: {
    onSelected(status) {
      if (status == 10) {
        this.$router.push({
          name: `backoffice-receipt-update`,
          params: { id: this.id },
          query: { pay: true }
        });
      } else if (status == 12) {
        this.cancel();
      }
    },
    cancel() {
      this.$alertConfirm({ title: `ยืนยันยกเลิกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ bill_id: this.id }, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios
              .post(`${this.$baseUrl}/backend/bill/cancel_bill`, body)
              .then(res => {
                this.$emit('success');
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    }
  },

  computed: {
    color() {
      let color = 'success';
      this.list.forEach(item => {
        if (this.status == item.value) color = item.color;
      });
      return color;
    }
  }
};
</script>
