import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/axios';
import './plugins/app';
import VueCookies from 'vue-cookies';
const jwt = require('jsonwebtoken');
const cors = require('cors')
import provinces from '@/assets/json/provinces.json';
import VueNumeralFilterInstaller from 'vue-numeral-filter';
import VueMoment from 'vue-moment';
const moment = require('moment');
require('moment/locale/th');
import 'moment-timezone';
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false;
const privateKey = 'yakyai';
// Vue.prototype.$baseUrl = 'http://ec2-18-141-228-125.ap-southeast-1.compute.amazonaws.com';
Vue.prototype.$baseUrl = 'https://api.permtang.com';
Vue.prototype.$jwt = jwt;
Vue.prototype.$privateKey = privateKey;
Vue.prototype.$provinces = provinces;

Vue.use(VueCookies);
Vue.use(VueNumeralFilterInstaller, { locale: 'en-gb' });
Vue.use(VueMoment, { moment });

let globalData = new Vue({
  data: {
    $isLoading: false,
    $isLoadingAlpha: false
  }
});

Vue.filter('moment', function (value, format) {
  if (value === '' || value === null || value === undefined || format === undefined) {
    return '';
  }
  if (format === 'from') {
    return moment(value).fromNow();
  }
  return moment(value).format(format);
});

Vue.mixin({
  computed: {
    $isLoading: {
      get: function () {
        return globalData.$data.$isLoading;
      },
      set: function (val) {
        globalData.$data.$isLoading = val;
      }
    },
    $isLoadingAlpha: {
      get: function () {
        return globalData.$data.$isLoadingAlpha;
      },
      set: function (val) {
        globalData.$data.$isLoadingAlpha = val;
      }
    }
  }
});

Object.filterPermission = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce(
      (res, key) =>
        Object.assign(res, {
          [key]: obj[key]
        }),
      {}
    );

Sentry.init({
  Vue,
  dsn: "https://6f7e8d32d659c2886c4dd2a99c3f478a@o4507611765342208.ingest.us.sentry.io/4507611768225792",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  environment: "frontend",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Vue.prototype.$sentry = Sentry;

router.beforeEach(async (to, from, next) => {
  try {
    // if (to.meta.authentication) {
    //   let accessToken = localStorage.getItem('access_token');
    //   if (!accessToken) throw new Error("Don't have permission.");

    //   accessToken = accessToken.replace('Bearer ', '');
    //   let decoded = await Vue.prototype.$jwt.decode(
    //     accessToken,
    //     Vue.prototype.$privateKey
    //   );

    //   let permissions = decoded.identity.permission.user_group;
    //   let allowPermission = Object.filterPermission(
    //     permissions,
    //     permission => permission == 1 || permission == 2
    //   );

    //   let permissionKeys = [...Object.keys(allowPermission), 'public'];
    //   if (permissionKeys.includes(to.meta.permission)) {
    //     return next();
    //   } else {
    //     throw new Error("Don't have permission.");
    //   }
    // } else 
    if (to.meta.boAuthentication) {
      let boAccessToken = localStorage.getItem('bo_access_token');
      if (boAccessToken) {
        return next();
      } else {
        throw new Error("Don't have permission.");
      }
    } else {
      return next();
    }
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);

    if (error.message === "Don't have permission.") {
      return next({
        path: '/pages/document/quotation',
        replace: true
      });
    } else {
      return next({
        path: '/login'
      });
    }
  }
});

new Vue({
  router,
  app,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate() {
    this.$store.dispatch('onReload');
    //this.$store.dispatch('getMyProfile');
    console.log('We Run')
    console.log(this.$store.state)
  }
}).$mount('#app');